<template>
  <div class="page">
    <div class="banner">
      <video class="video" loop controls :src="vUrl"></video>
    </div>
    <indexBom></indexBom>
    <floatBar></floatBar>
  </div>
</template>

<script>
import floatBar from "../../components/FloatBar";
import indexBom from "../../components/IndexBom";
export default {
  metaInfo: {
    title: "水木清芬",
    meta: [
      {
        name: "Index",
        content: "水木清芬首页",
      },
    ],
  },
  components: {
    floatBar,
    indexBom,
  },
  props: {},
  data() {
    return {
      vUrl: "http://file.smqfedu.cn/file/video/水木清芬宣传片.mp4",
      // vUrl: require("../../assets/video-tq.mp4"),
      // vUrl: "https://plus2-img.xizi.com/202103/31/lklm-Grdt-MbAL66jLV0ECmZgruG.mp4",
    };
  },
  watch: {},
  computed: {},
  methods: {
    toTop() {
      let time = setInterval(() => {
        document.documentElement.scrollTop += -50;
        if (document.documentElement.scrollTop === 0) {
          clearInterval(time);
        }
      }, 10);
    },
  },
  created() {
    this.toTop();
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.page {
  width: 100%;
}

.banner {
  width: 100%;
  height: 100vh;
  .video {
    position: relative;
    z-index: 100;
    margin-top: 66px;
    width: 100%;
    height: calc(100% - 66px);
  }
}
</style>

