<template>
  <div>
    <div class="content1">
      <div class="top">
        <div class="title">关于我们</div>
        <div class="line"></div>
        <div class="title1">ABOUT US</div>
      </div>
      <div class="bom">
        <div class="left animated" :class="{ bounceInLeft: show1 }">
          <div class="title">
            <div>公司简介</div>
            <div class="line"></div>
          </div>
          <div class="text">
            <div>
              北京水木清芬教育，课程体系由清华北大北师大中科院等名校专家与教研团队精心研发，由清华大学外语系蒋隆国教授担任总顾问，常年开设的课程有外教口语课、少儿英语课程、小学单词课、小学语法课、初/高中语法课、初/高中单词课、高考提分班和托福雅思词汇培训等。 教育的本质是一棵树摇动另一棵树，一朵云推动另一朵云，一个灵魂唤醒另一个灵魂，让我们一起携手并进，筑梦腾飞！
            </div>
          </div>
        </div>
        <div class="right animated" :class="{ bounceInRight: show1 }">
          <Carousel autoplay v-model="value" loop arrow="never" radius-dot>
            <CarouselItem>
              <img src="../assets/img/index/gywm1.png" class="swiper" />
            </CarouselItem>
            <CarouselItem>
              <img src="../assets/img/index/gywm2.png" class="swiper" />
            </CarouselItem>
            <CarouselItem>
              <img src="../assets/img/index/gywm3.png" class="swiper" />
            </CarouselItem>
          </Carousel>
        </div>
      </div>
    </div>
    <div class="content2">
      <div class="top">
        <div class="title">企业使命与愿景</div>
        <div class="line"></div>
        <div class="title1">CORPORATE MISSION AND VISION</div>
      </div>
      <div class="bom">
        <div class="item">
          <div class="text textbg1 animated" :class="{ bounceInLeft: show2 }">
            <div class="text1">
              <div class="text1box"></div>
              <div>01</div>
            </div>
            <div class="title">企业使命</div>
            <div class="info">以德育己 以心育人！人人为师 返璞归真！</div>
            <div class="line"></div>
          </div>
          <img
            class="img border1 animated"
            :class="{ bounceInRight: show2 }"
            src="../assets/img/index/qysm1.png"
          />
        </div>
        <div class="item">
          <img
            class="img border2 animated"
            :class="{ bounceInLeft: show3 }"
            src="../assets/img/index/qysm2.png"
          />
          <div class="text textbg2 animated" :class="{ bounceInRight: show3 }">
            <div class="text1">
              <div class="text1box"></div>
              <div>02</div>
            </div>
            <div class="title">企业愿景</div>
            <div class="info">用行动诠释教育的本质，用生命诠释生命的价值；让我们的教育足迹遍布中国每一个角落。</div>
            <div class="line"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="content3">
      <div class="top">
        <div class="title">企业文化</div>
        <div class="line"></div>
        <div class="title1">THE ENTERPRISE CULTURE</div>
      </div>
      <div class="bom">
        <div
          class="item"
          v-for="(item, index) in cultureList"
          :key="index"
          @mouseleave="mouseLeave"
          @mouseover="mouseOver(item.index)"
        >
          <div
            class="outside animated"
            :class="{ fadeOutDown: index == curIndex }"
          >
            <img :src="item.img" class="img" />
            <div class="imgb"></div>
            <div class="text">
              <div>
                <div class="text1">{{ item.text1 }}</div>
                <div class="text2">{{ item.text2 }}</div>
              </div>
            </div>
          </div>
          <div class="inside animated" :class="{ fadeInUp: index == curIndex }">
            <div class="text3">
              {{ item.text3 }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content4">
      <div class="top">
        <div class="title color">我们的特色</div>
        <div class="line"></div>
        <div class="title1 color">SHARED VALUES</div>
      </div>
      <div class="bom">
        <div
          class="item"
          :class="{ active: index == curIndex1 }"
          v-for="(item, index) in senseList"
          :key="index"
          @mouseleave="mouseLeave1"
          @mouseover="mouseOver1(item.index)"
        >
          {{ item.text }}
        </div>
      </div>
    </div>
    <div class="content5">
      <div class="top">
        <div class="title">发展历程</div>
        <div class="line"></div>
        <div class="title1">THE DEVELOPMENT COURSE</div>
      </div>
      <!-- <div class="bom">
        <div class="left">
          <div class="item">2019年，聚焦城南、立足城南的“天府新楼市”诞生</div>
          <div class="item li1">2014年，公司旗下PC网站“大天府网”诞生</div>
          <div class="item li2">
            2013年夏初，华阳生活百事通“华阳哪些事儿”诞生
          </div>
        </div>
        <div class="cen">
          <div class="item ci1">
            2019
            <img src="../assets/img/index/img-history1.png" class="img-h1" />
          </div>
          <div class="item ci2">
            2016
            <img src="../assets/img/index/img-history2.png" class="img-h2" />
          </div>
          <div class="item ci3">
            2014
            <img src="../assets/img/index/img-history1.png" class="img-h1" />
          </div>
          <div class="item ci4">
            2013
            <img src="../assets/img/index/img-history1.png" class="img-h1" />
          </div>
          <div class="line"></div>
        </div>
        <div class="right">
          <div class="item ri1">
            2016年，主打严选成都百分之二的好店，打造成为产品优、质量优、服务优的高端电商品牌“阳鲜生优选”诞生
          </div>
          <div class="item ri2">
            2016年，龙泉驿区综合资讯服 务平台“龙泉那些事儿”诞生
          </div>
          <div class="item ri3">
            2016年，立足天府新区， 专注家庭教育的亲子教育 平台“天府爸妈帮”诞生
          </div>
          <div class="item ri4">
            2016年，搜罗天府新区特色美食，聚集
            天府新区最火、最全面、最资格的美食 发布平台的“天府新区美食圈”诞生
          </div>
          <div class="item ri5">
            2016年，以诙谐、幽默的漫画形
            式呈现当下年轻人意识形态的“关心粮食执委会”诞生
          </div>
        </div>
      </div> -->
    </div>
    <div class="content6">
      <div class="top">
        <div class="title">公司荣誉</div>
        <div class="line"></div>
        <div class="title1">COMPANY HONOR</div>
      </div>
      <!-- <div class="bom">
        <Carousel v-model="curIndex3" loop arrow="always" dots="none">
          <CarouselItem>
            <div class="carousel-item">
              <div class="box">
                <div
                  class="item"
                  v-for="(item, index) in honorList1"
                  :key="index"
                >
                  <img class="img" :src="item.img" />
                  <div class="text">{{ item.text }}</div>
                </div>
              </div>
            </div>
          </CarouselItem>
          <CarouselItem>
            <div class="carousel-item">
              <div class="box">
                <div
                  class="item"
                  v-for="(item, index) in honorList2"
                  :key="index"
                >
                  <img class="img" :src="item.img" />
                  <div class="text">{{ item.text }}</div>
                </div>
              </div>
            </div>
          </CarouselItem>
        </Carousel>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      value: 0,
      cultureList: [
        {
          img: require("../assets/img/index/img11.png"),
          index: 0,
          text1: "诚实守信",
          text2: "Honest and trustworthy",
          text3:
            "诚实守信：诚实做人，不隐瞒自己的真实思想。不为不可告人的目的欺瞒客户、同事。讲信用，信守承诺，忠实于自己承担的义务。",
        },
        {
          img: require("../assets/img/index/img12.png"),
          index: 1,
          text1: "戒骄戒躁",
          text2: "Avoid arrogance",
          text3:
            "获得成就，不要骄傲自满。遇到挫折，不要慌乱急躁、一蹶不振，要一如既往的保持谦逊进取的精神。",
        },
        {
          img: require("../assets/img/index/img13.png"),
          index: 2,
          text1: "团结创新",
          text2: "Unity innovation",
          text3: "互相支持，互相配合，互相理解，互相包容。",
        },
        {
          img: require("../assets/img/index/img14.png"),
          index: 3,
          text1: "拥抱变化",
          text2: "Embrace change",
          text3:
            "面对变化，理性对待，充分沟通，诚意配合。对变化产生的困难和挫折，能自我调整，并正面影响和带动同事。有前瞻意识，创造变化，建立新方法、新思路。",
        },
      ],
      curIndex: null,
      senseList: [
        { text: "承载传统育人", index: 0 },
        { text: "坚持立德育人", index: 1 },
        { text: "注重行为育人", index: 2,},
        { text: "探究教学育人", index: 3 },
      ],
      curIndex1: null,
      honorList1: [
        {
          img: require("../assets/img/index/img-honor1.png"),
          text: "成都天驱科技有限公司荣获“华阳街道2017年度经济工作先进企业”荣誉",
          index: 0,
        },
        {
          img: require("../assets/img/index/img-honor2.png"),
          text: "华阳那些事儿荣获2017成都市属地互联网品牌评选活动 “区域服务类”上榜品牌",
          index: 1,
        },
        {
          img: require("../assets/img/index/img-honor3.png"),
          text: "天府发布荣获2018新浪四川微政道影响力政务微博“最佳创新传播奖”",
          index: 2,
        },
      ],
      honorList2: [
        {
          img: require("../assets/img/index/img-honor4.png"),
          text: "天府发布被评为“微政四川2018年度十佳区县政务新媒体（成都）”",
          index: 3,
        },
        {
          img: require("../assets/img/index/img-honor5.png"),
          text: "华阳那些事儿荣获2019年度成都市自媒体协会 “星力榜”",
          index: 4,
        },
        {
          img: require("../assets/img/index/img-honor6.png"),
          text: "2019年1月成都天驱科技有限公司荣获#成都地铁24小时#短视频大赛二等奖",
          index: 5,
        },
      ],
      curIndex2: null,
      curIndex3: 0,
      num: 0,
      moveL: false,
      moveR: false,
      show1: false,
      show2: false,
      show3: false,
    };
  },
  watch: {},
  computed: {},
  methods: {
    mouseOver(i) {
      this.curIndex = i;
    },
    mouseLeave() {
      this.curIndex = null;
    },
    mouseOver1(i) {
      this.curIndex1 = i;
    },
    mouseLeave1() {
      this.curIndex1 = null;
    },
    leftLeave() {
      this.moveL = false;
    },
    leftOver() {
      this.moveL = true;
      let time = setInterval(() => {
        if (this.moveL) {
          if (this.num > -740) {
            this.num -= 2;
          }
        } else {
          clearInterval(time);
        }
      }, 10);
    },
    rightLeave() {
      this.moveR = false;
      this.time = null;
    },
    rightOver() {
      this.moveR = true;
      let time = setInterval(() => {
        if (this.moveR) {
          if (this.num < 0) {
            this.num += 2;
          }
        } else {
          clearInterval(time);
        }
      }, 10);
    },
    handleScroll() {
      var scrollTop = document.documentElement.scrollTop;
      // console.log(scrollTop);
      if (scrollTop >= 200) {
        this.show1 = true;
      }
      if (scrollTop >= 850) {
        this.show2 = true;
      }
      if (scrollTop >= 1140) {
        this.show3 = true;
      }
    },
  },
  created() {},
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
};
</script>
<style lang="less" scoped>
.top {
  text-align: center;
  .title {
    color: #000028;
    font-size: 24px;
  }
  .line {
    width: 40px;
    height: 1px;
    background: #19793a;
    opacity: 1;
    margin: 0 auto;
    margin-top: 8px;
    margin-bottom: 7px;
  }
  .title1 {
    font-size: 14px;
    color: #999999;
  }
}
.content1 {
  width: 1600px;
  height: 689px;
  margin: 0 auto;
  padding-top: 70px;
  .bom {
    margin-top: 60px;
    margin-bottom: 50px;
    display: flex;
    justify-content: space-between;
    .left {
      width: 620px;
      height: 400px;
      .title {
        color: #4d4d4d;
        font-size: 20px;
        .line {
          width: 40px;
          height: 3px;
          background: #19793a;
          opacity: 1;
          margin-top: 10px;
        }
      }
      .text {
        margin-top: 35px;
        color: #000014;
        line-height: 30px;
        font-size: 16px;
        letter-spacing: 2px;
        text-align: justify;
      }
    }
    .right {
      width: 800px;
      height: 420px;
      /deep/.ivu-carousel-list {
        width: 800px;
        height: 420px;
        border-radius: 10px !important;
      }
      .swiper {
        width: 800px;
        height: 420px;
        border-radius: 10px !important;
      }
    }
  }
}
.content2 {
  width: 100%;
  height: 770px;
  background: #f0f5fa;
  padding-top: 30px;
  .bom {
    width: 1600px;
    height: 580px;
    margin: 0 auto;
    margin-top: 60px;
    .item {
      width: 1600px;
      height: 280px;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      .img {
        width: 1190px;
        height: 280px;
      }
      .border1 {
        border-top-right-radius: 10px;
      }
      .border2 {
        border-bottom-left-radius: 10px;
      }

      .text {
        width: 400px;
        height: 280px;
        color: #fff;
        .text1 {
          margin-top: 60px;
          display: flex;
          justify-content: space-between;
          font-size: 20px;
          margin-left: 50px;
          margin-right: 42px;
          .text1box {
            width: 22px;
            height: 4px;
            background: #fff;
          }
        }
        .title {
          font-size: 20px;
          margin-left: 50px;
          margin-right: 42px;
          margin-top: 60px;
        }
        .info {
          margin-top: 10px;
          font-size: 16px;
          margin-left: 50px;
          margin-right: 42px;
        }
        .line {
          margin-top: 24px;
          width: 400px;
          height: 1px;
          background: #fff;
        }
      }
      .textbg1 {
        background: #10b4fc;
        border-top-left-radius: 10px;
      }
      .textbg2 {
        background: #19793a;
        border-bottom-right-radius: 10px;
      }
    }
  }
}
.content3 {
  padding-top: 70px;
  width: 100%;
  height: 760px;
  .bom {
    width: 1600px;
    height: 500px;
    margin: 0 auto;
    display: flex;
    margin-top: 60px;
    justify-content: space-between;
    .item {
      overflow: hidden;
      width: 350px;
      height: 500px;
      border-radius: 10px;
      .outside {
        z-index: 10;
        position: relative;
        border-radius: 10px;
        .img {
          width: 350px;
          height: 500px;
          position: absolute;
          z-index: 10;
        }
        .imgb {
          width: 350px;
          height: 500px;
          background: rgba(0, 64, 159, 0.5);
          position: absolute;
          z-index: 11;
        }
        .text {
          width: 350px;
          height: 500px;
          position: absolute;
          z-index: 20;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          .text1 {
            font-size: 20px;
            font-weight: 500;
            text-align: center;
          }
          .text2 {
            text-align: center;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
      .inside {
        position: relative;
        z-index: 9;
        width: 350px;
        height: 500px;
        background: #19793a;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        .text3 {
          text-align: justify;
          height: 220px;
          width: 262px;
          line-height: 24px;
          color: #ffffff;
          font-size: 16px;
          border-top: 2px solid #fff;
          border-bottom: 2px solid #fff;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
.content4 {
  width: 100%;
  height: 402px;
  background-image: url("../assets/img/index/content-bg.png");
  background-repeat: no-repeat;
  background-size: auto;
  padding-top: 30px;
  .color {
    color: #fff;
  }
  .bom {
    width: 1600px;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 60px;
    .item {
      width: 512px;
      height: 68px;
      text-align: center;
      line-height: 68px;
      background: #ffffff;
      opacity: 1;
      border-radius: 10px;
      margin-bottom: 33px;
      padding-left: 40px;
      font-size: 16px;
      color: #000028;
    }
    .active {
      position: relative;
      animation: mymove 0.5s;
      animation-fill-mode: forwards;
    }
    @keyframes mymove {
      from {
        top: 0px;
      }
      to {
        top: -10px;
      }
    }
    .item:hover {
      box-shadow: 0px 0px 6px rgba(16, 180, 252, 0.79);
    }
  }
}
.content5 {
  width: 100%;
  height: 903px;
  padding-top: 70px;
  .bom {
    width: 1036px;
    margin: 0 auto;
    display: flex;
    margin-top: 60px;
    .left {
      width: 394px;
      height: 690px;
      .item {
        width: 394px;
        padding-top: 22px;
        padding-bottom: 22px;
        padding-left: 16px;
        padding-right: 16px;
        color: #000014;
        font-size: 16px;
        background: #f8f8f8;
        opacity: 1;
        border-radius: 10px;
      }
      .item:hover {
        background: #19793a;
        color: #ffffff;
      }
      .li1 {
        margin-top: 180px;
      }
      .li2 {
        margin-top: 122px;
      }
    }
    .cen {
      width: 246px;
      height: 690px;
      position: relative;
      .item {
        width: 102px;
        height: 38px;
        background: #19793a;
        opacity: 1;
        border-radius: 19px;
        color: #fff;
        font-size: 20px;
        font-weight: 500;
        text-align: center;
        line-height: 38px;
        margin: 0 auto;
        position: relative;
        .img-h1 {
          width: 80px;
          height: 16px;
          position: absolute;
          top: 12px;
          left: -60px;
        }
        .img-h2 {
          width: 80px;
          height: 16px;
          position: absolute;
          top: 12px;
          right: -60px;
        }
      }
      .ci1 {
        margin-top: 10px;
      }
      .ci2 {
        margin-top: 106px;
      }
      .ci3 {
        margin-top: 70px;
      }
      .ci4 {
        margin-top: 150px;
      }
      .line {
        position: absolute;
        top: 10px;
        left: 123px;
        z-index: -1;
        width: 6px;
        height: 650px;
        background: #ebebeb;
        border-radius: 3px;
      }
    }
    .right {
      width: 388px;
      height: 690px;
      .item {
        width: 388px;
        padding-top: 22px;
        padding-bottom: 22px;
        padding-left: 16px;
        padding-right: 16px;
        color: #000014;
        font-size: 16px;
        background: #f8f8f8;
        opacity: 1;
        border-radius: 10px;
        line-height: 20px;
      }
      .item:hover {
        background: #19793a;
        color: #ffffff;
      }
      .ri1 {
        margin-top: 120px;
      }
      .ri2 {
        margin-top: 15px;
      }
      .ri3 {
        margin-top: 16px;
      }
      .ri4 {
        margin-top: 16px;
      }
      .ri5 {
        margin-top: 15px;
      }
    }
  }
}
.content6 {
  width: 100%;
  height: 655px;
  background: #f0f5fa;
  padding-top: 30px;
  .bom {
    margin: 0 auto;
    width: 1600px;
    height: 402px;
    margin-top: 60px;
    .carousel-item {
      width: 1600px;
      height: 402px;
      .box {
        display: flex;
        justify-content: space-around;
        width: 1104px;
        height: 402px;
        margin: 0 auto;
        .item {
          width: 340px;
          border-radius: 10px;
          background: #fff;
          img {
            width: 340px;
            height: 268px;
          }
          .text {
            width: 340px;
            height: 132px;
            color: #000014;
            font-size: 16px;
            display: flex;
            align-items: center;
            padding-left: 26px;
            padding-right: 34px;
          }
        }
      }
    }
  }
}
</style>